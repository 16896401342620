<template>
  <WeContainer v-if="ready" v-bind:actions="actions">
    <WeTable
      v-bind:index="false"
      v-bind:data="marketplace.list"
      v-bind:columns="columns"
      v-bind:actions="actions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      actions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
      ],
      columns: [
        {
          name: "logo",
          icon: "fas fa-camera",
          type: "image_url",
          width: "15%",
        },
        { name: "title", th: "Pazaryeri", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean", width: "10%" },
      ],
    };
  },
  methods: {
    ...mapActions("marketplace", ["getList", "load", "isActive"]),
    onAction(data) {
      if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("marketplaces/detail/" + row.id);
    },
    onSwitch(data) {
      this.isActive({
        form: data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$toast.success("Durum Güncellendi");
          } else {
            this.$toast.error("Durum Güncellenemedi");
          }
        },
        onError: (error) => {
          this.$toast.error("Durum Güncellenemedi");
        },
      });
    },
  },
  computed: {
    ...mapState(["marketplace"]),
  },
  mounted() {
    this.load();
    this.getList({
      onFinish: () => {
        this.ready = true;
      },
    });
  },
};
</script>
